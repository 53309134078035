<template>
  <v-layout id="dashboard" style="background: #e5e5e5de ;" column class="px-0">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-layout class="d-flex">
        <div style="flex: 60;" class="font-table scroll-left">
          <v-layout column class="mx-2 mt-2">
            <h4>
              {{ $t('place_holders.shop_order') }}
              <span class="red--text">
                {{ $t("icon_required") }}
              </span>
            </h4>
            <validation-provider
              v-slot="{ errors }"
              name="selectRequire"
              vid="shopOrder"
              :rules="requireRules"
            >
              <v-autocomplete
                v-model="shopOrderSelected"
                outlined
                dense
                attach
                :no-data-text="$t('no_data')"
                class="rounded"
                item-text="name"
                item-value="id"
                :items="listShop"
                :error-messages="errors"
                :filter="filterItems"
                :placeholder="$t('place_holders.shop_order')"
                @change="changeShop"
              />
            </validation-provider>
          </v-layout>
          <v-layout column class="mx-2">
            <!-- Địa chỉ người gửi -->
            <v-layout justify-start>
              <h4>
                {{ $t("info-sender") }}
                <span class="red--text">
                  {{ $t("icon_required") }}
                </span>
              </h4>
            </v-layout>
            <validation-provider
              nane="address_sender"
              :rules="requireRules"
            >
              <v-text-field v-show="false" v-model="itemAddressSelected" />
            </validation-provider>
            <v-btn
              v-if="shopOrderSelected && isShowAddressCreateForOrder"
              class="container--fluid"
              outlined
              @click="onShowAddressCreate"
            >
              {{ $t("add_sender_address") }}
            </v-btn>
            <span
              v-else-if="!shopOrderSelected"
              class="error--text"
              style="align-self: center"
            >
              {{ $t("msg-empty-address") }}
            </span>
            <v-layout v-else class="border rounded pl-2 pr-8 my-2 py-2">
              <v-row>
                <v-col cols="sm-8" class="col-12">
                  <v-layout column class="mr-4">
                    <label class="text-subtitle-2">
                      {{ getTextTruncateView(itemAddressSelected.name, 30) }}
                      <span
                        v-if="itemAddressSelected.defaultAddress"
                        class="error--text text-min"
                      >
                        [{{ $t("default") }}]
                      </span>
                    </label>
                    <label
                      class="text-caption blur--text line-height-small"
                    >
                      {{ itemAddressSelected.phone }}
                    </label>
                    <label
                      class="text-caption blur--text line-height-small"
                    >
                      {{
                        `${getTextTruncateView(itemAddressSelected.addressDetail, 30)}, ${getTextTruncateView(itemAddressSelected.communeName, 30)}, ${getTextTruncateView(itemAddressSelected.districtName, 30)}, ${getTextTruncateView(itemAddressSelected.provinceName, 30)}`
                      }}
                    </label>
                  </v-layout>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn
                    color="primary"
                    outlined
                    @click="onShowAddressList"
                  >
                    {{ $t("change") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
            <!--nguoi nhan-->
            <v-layout justify-start class="mt-4">
              <h4>{{ $t("info-receiver") }}</h4>
            </v-layout>
            <v-layout column>
              <div>
                {{ $t("receiver_full_name") }}
                <span class="red--text">
                  {{ $t("icon_required") }}
                </span>
              </div>
              <validation-provider
                v-slot="{ errors }"
                name="require"
                vid="fullName"
                :rules="requireRules"
              >
                <v-text-field
                  v-model="fullName"
                  outlined
                  dense
                  :error-messages="errors"
                  :maxlength="maximumSmallText"
                  :placeholder="$t('place_holders.type_full_name')"
                  @change="fullName = trimSpace(fullName)"
                />
              </validation-provider>
            </v-layout>
            <v-layout column>
              <div>
                {{ $t("receiver_phone") }}
                <span class="red--text">
                  {{ $t("icon_required") }}
                </span>
              </div>
              <validation-provider
                v-slot="{ errors }"
                name="phone"
                :rules="phoneRules"
              >
                <v-text-field
                  v-model="phone"
                  type="text"
                  pattern="\d*"
                  outlined
                  dense
                  :class="receiverPhoneError ? 'error--text v-input--has-state': ''"
                  :hide-details="receiverPhoneHideError"
                  :error-messages="errors"
                  :maxlength="maximumPhone"
                  :placeholder="$t('place_holders.type_phone')"
                  @keypress="isNumber($event)"
                  @paste.prevent="onPastePhone($event)"
                  @input="inputPhone($event)"
                  @change="phone = trimSpace(phone)"
                />
                <div
                  v-if="receiverPhoneError"
                  class="v-messages theme--light error--text mt-1 ml-3"
                  role="alert"
                >
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ receiverPhoneError }}
                    </div>
                  </div>
                </div>
                <div class="pos-rel">
                  <v-menu
                    v-model="menuPhone"
                    class="list-receiver"
                    :nudge-width="200"
                    offset-x
                    attach
                  >
                    <v-card>
                      <v-list
                        v-for="(item, index) in listReceiver"
                        :key="index"
                      >
                        <v-list-item @click="choosePhone(item.phone)">
                          <v-layout column>
                            <span class="text--body-5-14">
                              {{ item.phone }} - {{ item.name }}
                            </span>
                            <span class="text--body-5-12">
                              {{ `${item.address}, ${item.communeName}, ${item.districtName}, ${item.provinceName}` }}
                            </span>
                          </v-layout>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </validation-provider>
            </v-layout>
            <!-- Địa chỉ nhận hàng -->
            <v-layout column>
              <label>
                {{ $t("province") }}/{{ $t("district") }}/{{
                  $t("commune")
                }}
                <span class="error--text">*</span>
              </label>
              <v-row>
                <validation-provider
                  v-slot="{ errors }"
                  name="selectRequire"
                  vid="provinceOrder"
                  class="container col-sm-4 col-12"
                  :rules="requireRules"
                >
                  <v-autocomplete
                    ref="inputProvinceOrder"
                    v-model="provinceOrderCodeSelected"
                    outlined
                    dense
                    class="rounded"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :items="lstProvince"
                    :filter="filterItems"
                    :placeholder="$t('place_holders.province')"
                    :error-messages="errors"
                    @change="onChangeProvinceOrder"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  ref="districtOrder"
                  name="selectRequire"
                  vid="districtOrder"
                  class="container col-sm-4 col-12 col-12 py-0 py-sm-3"
                  :rules="requireRules"
                >
                  <v-autocomplete
                    ref="inputDistrictOrder"
                    v-model="districtOrderSelected"
                    outlined
                    dense
                    class="rounded"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :items="lstDistrictOrder"
                    :filter="filterItems"
                    :placeholder="$t('place_holders.district')"
                    :error-messages="errors"
                    :disabled="districtDisabledOrder"
                    @change="onChangeDistrictOrder"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  ref="communeOrder"
                  name="selectRequire"
                  vid="communeOrder"
                  class="container col-sm-4"
                  :rules="requireRules"
                >
                  <v-autocomplete
                    ref="inputCommuneOrder"
                    v-model="communeOrderSelected"
                    outlined
                    dense
                    class="rounded"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :items="lstCommuneOrder"
                    :filter="filterItems"
                    :placeholder="$t('place_holders.commune')"
                    :error-messages="errors"
                    :disabled="communeDisabledOrder"
                    @change="onChangeCommuneOrder"
                  />
                </validation-provider>
              </v-row>
            </v-layout>
            <!-- Địa chỉ cụ thể -->
            <v-layout>
              <v-row>
                <v-col>
                  <div>
                    {{ $t("custom_address") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="require"
                    vid="customAddress"
                    :rules="requireRules"
                  >
                    <v-text-field
                      v-model="customAddress"
                      outlined
                      dense
                      :maxlength="maximumSmallText"
                      :error-messages="errors"
                      :placeholder="$t('place_holders.type_custom_address')"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-layout>
            <!--Thong tin kien hang-->
            <v-layout justify-start>
              <h4>{{ $t("package_info") }}</h4>
            </v-layout>
            <v-layout column>
              <input-info-order
                v-for="(item, index) in lstOrder"
                ref="inputInfoOrder"
                :key="index"
                :item="item"
                :idx="index"
                :lst-order="lstOrder"
                :on-delete-item="onDeleteItem"
                :on-blur-value="onBlurValue"
                class="mt-2"
              />
            </v-layout>
            <v-layout v-if="lstOrder.length < 30" justify-start class="pb-2 mt-2">
              <v-icon
                color="primary"
                size="25"
                @click="addItemOrder()"
              >
                mdi-plus-circle-outline
              </v-icon>
              <div @click="addItemOrder()">
                Thêm kiện hàng
              </div>
            </v-layout>
          </v-layout>
        </div>
        <div style="flex: 1" />
        <div style="background: white; flex: 48;" class="ml-2 font-table scroll-right">
          <v-layout column class="mx-2 mt-2">
            <!--Thong tin van chuyen-->
            <v-layout justify-start>
              <h4>{{ $t("info-shipping") }}</h4>
            </v-layout>
            <v-layout>
              <v-row>
                <v-col>
                  <div>
                    {{ $t("group-shipping") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="selectRequire"
                    vid="partnerName"
                    :rules="requireRules"
                  >
                    <v-select
                      v-model="partnerSelect"
                      outlined
                      dense
                      :disabled="!shopOrderSelected"
                      :items="lstPartner"
                      item-text="name"
                      item-value="value"
                      :error-messages="errors"
                      :no-data-text="$t('no-data')"
                      :placeholder="$t('place_holders.choice_group_shipping')"
                      @change="onChangeGetServiceAll"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-layout>
            <v-layout>
              <v-row>
                <v-col>
                  <div>
                    {{ $t("shipping_service") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="selectRequire"
                    vid="serviceName"
                    :rules="requireRules"
                  >
                    <v-select
                      v-model="serviceSelect"
                      outlined
                      dense
                      item-text="name"
                      item-value="value"
                      disabled
                      filled
                      :hide-details="showMsgErrorService"
                      :items="lstService"
                      :error-messages="errors"
                      :placeholder="showMsgErrorService ? $t('msg_get_price_over') : $t('place_holders.select_delivery_service')"
                      @change="onChangeCheckFeeTagService"
                    />
                  </validation-provider>
                  <span v-if="showMsgErrorService" class="text-date-error">
                    {{ $t(msgErrorService) }}
                  </span>
                </v-col>
              </v-row>
            </v-layout>

            <!--Hinh thuc gui hang-->
            <v-layout justify-start>
              <div>{{ $t("shipping_method") }}</div>
            </v-layout>
            <v-layout justify-start>
              <v-radio-group
                v-model="transportType"
                mandatory
                :disabled="!shopOrderSelected"
                row
                class="mt-0"
                @change="onChangeTransportType"
              >
                <v-radio
                  :label="$t('customer_bring_order')"
                  :value="transportTypeConst.individual"
                />
                <v-radio
                  :label="$t('shipper_pickup')"
                  :value="transportTypeConst.ship"
                />
              </v-radio-group>
              <v-tooltip
                v-if="showNjv"
                top
                max-width="250"
                color="white"
                class="box-shadow"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    small
                    v-bind="attrs"
                    style="top: -6px"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span style="color: black">
                  {{ $t('tooltip_njv_method') }}
                </span>
              </v-tooltip>
            </v-layout>

            <v-layout>
              <v-row v-if="isShowPickUpDateShipping">
                <v-col cols="sm-6" class="col-12 pb-0">
                  <div>
                    {{ $t("pickup_date") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <v-menu
                    v-model="menuPickUpDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="selectRequire"
                        vid="datePicker"
                        :rules="requireRules"
                      >
                        <v-text-field
                          v-model="pickUpDateFm"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          :placeholder="$t('place_holders.input_date')"
                          :error-messages="errors"
                          v-bind="attrs"
                          @blur="pickUpDate = parseDate(pickUpDateFm)"
                          v-on="on"
                        />
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="pickUpDate"
                      no-title
                      :locale="locale"
                      :allowed-dates="partnerSelect === constants.partnerGroup.ninjaVan ? allowedDates : null"
                      :min="minAllowedDate"
                      :max="maxAllowedDate"
                      @input="onInputPickUpDate"
                    />
                  </v-menu>
                </v-col>
                <v-col v-if="partnerSelect === constants.partnerGroup.ninjaVan" cols="sm-6" class="col-12 pt-0 pt-sm-3">
                  <div>
                    {{ $t("pickup_time") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    ref="timePicker"
                    name="selectRequire"
                    vid="timePicker"
                    :rules="requireRules"
                  >
                    <v-select
                      v-model="timePicker"
                      outlined
                      dense
                      no-data-text=""
                      item-text="text"
                      return-object
                      :items="timePickerList"
                      :error-messages="errors"
                      :placeholder="$t('place_holders.time_picker')"
                      :disabled="timePickerDisabled"
                    />
                  </validation-provider>
                </v-col>
                <v-col v-if="partnerSelect === constants.partnerGroup.ghtk" cols="sm-6" class="col-12 pt-0 pt-sm-3">
                  <div>
                    {{ $t("pickup_time") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    ref="pickWorkShip"
                    name="selectRequire"
                    vid="pickWorkShip"
                    :rules="requireRules"
                  >
                    <v-select
                      v-model="pickWorkShip"
                      outlined
                      dense
                      item-text="name"
                      item-value="code"
                      :disabled="timePickerDisabled"
                      :items="listPickWorkShipGhtk"
                      :error-messages="errors"
                      :placeholder="$t('place_holders.time_picker')"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-layout>
            <v-layout>
              <v-row>
                <!-- Doi tuong tra dich vu -->
                <v-col>
                  <div>
                    {{ $t("payer") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="selectRequire"
                    vid="serviceFeePayers"
                    :rules="requireRules"
                  >
                    <v-radio-group
                      v-model="serviceFeePayer"
                      row
                      class="mt-0"
                      :error-messages="errors"
                      @change="onChangeServiceFeePayer"
                    >
                      <v-radio
                        :label="$t('sender')"
                        :value="serviceFeePayersConst.sender"
                      />
                      <v-radio
                        :label="$t('receiver')"
                        :value="serviceFeePayersConst.receiver"
                      />
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-layout>
            <!--Ma van don theo yeu cau-->
            <v-layout v-if="showNjv">
              <v-row>
                <v-col>
                  <div>
                    {{ $t("way_bill_required") }}
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="waybillrequire"
                    :rules="waybillRules"
                  >
                    <v-text-field
                      v-model="wayBillRequire"
                      outlined
                      dense
                      class="border-combine-left"
                      :maxlength="maximumWaybill"
                      :placeholder="$t('place_holders.way_bill_required')"
                      :error-messages="errors"
                    >
                      <v-icon
                        slot="prepend-inner"
                        class="mr-2"
                        style="font-style: normal"
                      >
                        {{ waybillPrefix }}
                      </v-icon>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-layout>

            <!-- Ghi chú giao hàng -->
            <v-layout v-if="showNjv">
              <v-row>
                <v-col>
                  <div>
                    {{ $t("note_order") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="selectRequire"
                    vid="deliveryNote"
                    :rules="requireRules"
                  >
                    <v-select
                      v-model="deliveryNoteSelected"
                      outlined
                      dense
                      class="rounded"
                      item-text="name"
                      item-value="code"
                      hide-no-data
                      :items="lstDeliveryNote"
                      :placeholder="$t('place_holders.type_order_note')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-layout>
            <!--cod-->
            <v-layout>
              <v-row>
                <v-col>
                  <div>
                    {{ $t("cod") }}
                    <span class="red--text">
                      {{ $t("icon_required") }}
                    </span>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="require"
                    vid="cod"
                    :rules="requireRules"
                  >
                    <v-text-field
                      v-model="cod"
                      dense
                      outlined
                      :class="codValueError ? 'error--text v-input--has-state': ''"
                      :hide-details="codValueHideError"
                      :maxlength="maximumPriceFm"
                      :error-messages="errors"
                      :placeholder="$t('place_holders.type_cod')"
                      @keypress="isNumber($event)"
                      @input="onInputCOD"
                      @paste.prevent="onPasteCOD($event)"
                    />
                    <div
                      v-if="codValueError"
                      class="v-messages theme--light error--text mt-1 ml-3"
                      role="alert"
                    >
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                          {{ codValueError }}
                        </div>
                      </div>
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-layout>

            <!-- dich vu -->
            <div class="d-flex">
              <v-layout column>
                <div>
                  {{ $t("establish-service") }}
                </div>
                <div v-for="(item, index) in lstFeeTag" :key="index">
                  <v-checkbox
                    v-model="checkedFeeLst"
                    multiple
                    hide-details
                    :disabled="item.isDisableInsurance"
                    :label="item.name"
                    :value="item.id"
                    class="check-box-create"
                    @change="checkCode(item.id, item.unit, item.value)"
                  >
                    <template v-slot:label>
                      <v-layout class="d-flex justify-space-between">
                        <div class="max-line-1">
                          {{ item.name }}
                        </div>
                        <div>
                          <v-tooltip
                            top
                            nudge-left="100"
                            max-width="250"
                            color="white"
                            class="box-shadow ml-4"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <span style="color: #0F90E8; text-decoration: underline; cursor: pointer">{{ item.description }}</span>
                              </div>
                            </template>
                            <label v-html="item.note" />
                          </v-tooltip>
                        </div>
                      </v-layout>
                    </template>
                  </v-checkbox>
                </div>
              </v-layout>
            </div>
            <!-- Phí phải trả -->
            <v-layout column class="mt-2">
              <v-layout justify-space-between>
                <h6 class="text-subtitle-1 font-weight-medium">
                  {{ $t("fee_delivery") }} ({{ $t("vnd_unit") }})
                </h6>
                <h6 class="text-subtitle-1 font-weight-medium">
                  {{ transportFee }}
                </h6>
              </v-layout>
              <v-layout justify-space-between>
                <h6 class="text-subtitle-1 font-weight-medium">
                  {{ $t("fee_total") }} ({{ $t("vnd_unit") }})
                </h6>
                <h6 class="text-subtitle-1 font-weight-medium">
                  {{ ServiceFee }}
                </h6>
              </v-layout>
              <v-layout justify-space-between>
                <h6 class="text-subtitle-1 font-weight-medium">
                  {{ $t("total_service") }} ({{ $t("vnd_unit") }})
                </h6>
                <h6 class="text-subtitle-1 font-weight-medium">
                  {{ totalService }}
                </h6>
              </v-layout>
            </v-layout>
            <!-- Tổng thu -->
            <v-layout class="border rounded pl-2 pr-2 pr-sm-8 my-2 py-2">
              <v-row>
                <v-col cols="sm-10" class="col-12 d-flex">
                  <img
                    src="@/assets/icons/ic-cash.svg"
                  >
                  <span style="align-self: center">
                    {{ $t("total_revenue") }} ({{ $t("vnd_unit") }})
                  </span>
                </v-col>
                <v-col class="text-right">
                  <span class="text-h5">{{ totalRevenue }}</span>
                </v-col>
              </v-row>
            </v-layout>
          </v-layout>
        </div>
      </v-layout>
      <v-footer class="mt-4 font-table">
        <v-layout class="d-flex justify-end">
          <v-btn outlined @click="onShowConfirmResetForm">{{
            $t("re_type")
          }}</v-btn>
          <v-btn
            :disabled="invalid || checkBtnCreate || disableBtnCreate || showMsgErrorService"
            color="primary"
            class="ml-3"
            @click="onOrderCreate"
          >
            {{ $t("create_order") }}
          </v-btn>
        </v-layout>
      </v-footer>
    </validation-observer>
    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />
    <!-- Dialog create and edit address -->
    <dialog-create-and-edit-address
      v-model="showCreateAndEditAddress"
      :item="itemAddress"
      :default-origin="defaultAddressOrigin"
      :lst-province="lstProvince"
      :on-address-action="onAddressCreateOrUpdate"
      :on-cancel-action="onClickCancelDialogCreateAddress"
    />
    <!-- Dialog Address List -->
    <dialog-address-list
      v-model="showAddressList"
      :items="lstAddressByPage"
      :on-add-address="onGoAddAddressFromList"
      :on-detail-address="onDetailAddress"
      :on-delete-address="onShowConfirmDeleteAddress"
      :on-accept-selected-address="onAcceptSelectedAddress"
      :on-selected-address="onSelectedAddress"
      :page="page"
      :total-page="totalPage"
      :total-record="totalRecord"
      :on-change-page="onChangePage"
    />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- dialog confirm del address -->
    <dialog-confirm
      v-model="showAddressConfirmDel"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAcceptAddressConfirmDel"
      :on-cancel="onCancelAddressConfirmDel"
      :on-accept="onAcceptAddressConfirmDel"
      :data="itemAddressDelete"
    />

    <!-- dialog confirm reset form -->
    <dialog-confirm
      v-model="showConfirmResetForm"
      :width-dialog="300"
      :icon-src="getIcon"
      :message="message"
      :message2="message2"
      :text-cancel="textCancel"
      :text-accept="textAcceptConfirmResetForm"
      :on-cancel="onCancelConfirmResetForm"
      :on-accept="onAcceptConfirmResetForm"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </v-layout>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import mixinHandleOrderCreateMultiBox from "@/views/create_order/mixins/mixinHandleOrderCreateMultiBox";
import mixinHandleAddress from "./mixins/mixinHandleAddress";
import DialogCreateAndEditAddress from "./components/DialogCreateAndEditAddress.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogAddressList from "./components/DialogAddressList.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import inputInfoOrder from "@/views/create_order/components/inputInfoOrder"
import constants from "@/constants";
import filterItems from "@/helpers/filterItems";
import { StringUtils } from "@/helpers/stringUtils";
import { FormUtils } from "@/helpers/formUtils";
import { StorageService } from "@/services/storageService";
import mixinFormKevox from "./mixins/mixinFormKevox"

export default {
  components: {
    DialogCreateAndEditAddress,
    DialogAddressList,
    DialogNotification,
    DialogConfirm,
    DialogConfirmTokenExpried,
    DialogLoading,
    inputInfoOrder
  },
  mixins: [mixinHandleOrderCreateMultiBox, mixinHandleAddress, filterItems, mixinFormKevox],
  data() {
    return {
      constants,
      requireRules: {
        required: true,
      },

      lstProvince: [],

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showConfirmTokenExpried: false,
      textCancel: this.$t("cancel"),
      keyCode: null,
      showLoading: false,
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
  },
  // mounted() {
  //   if (localStorage.getItem('reloaded')) {
  //     // The page was just reloaded. Clear the value from local storage
  //     // so that it will reload the next time this page is visited.
  //     localStorage.removeItem('reloaded');
  //   } else {
  //     // Set a flag so that we know not to reload the page twice.
  //     localStorage.setItem('reloaded', '1');
  //     location.reload();
  //   }
  // },
  beforeMount() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getProvinces();
      await this.getListDeliveryNote();
      // await this.getLastTransportType();
      await this.getShops();
      await this.getAllPickWorkShip()
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces();
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    isNumber(event) {
      if (event.key === ".") {
        return event.preventDefault();
      } else {
        return FormUtils.isNumber(event);
      }
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    isNumberValue(evt, value) {
      return FormUtils.isNumber(evt) && this.isNumberPrice(evt, value)
    },
    isDecimal(evt) {
      return FormUtils.isDecimal(evt);
    },
  },
};
</script>
<style scoped>
#dashboard {
  margin: -16px -12px;
  background: #e5e5e5;
}
.font-table {
  background: white;
  border-radius: 8px;
}
.v-main__wrap {
  flex: 50 !important;
}
.scroll-left {
  height: calc(100vh - 175px);
  overflow-y: initial;
  overflow-x: hidden;
}
.scroll-right {
  height: calc(100vh - 175px);
  overflow-y: initial;
  overflow-x: hidden;
}
.text-date-error {
    font-size: 12px;
    color: #ff5252;
    margin-top: 3px;
    margin-left: 12px;
    font-weight: 600;
  }
</style>
